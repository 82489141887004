.dropdown-button-outer {
  position: relative;
  z-index: 100;

  &:last-child .dropdown-outer {
    right: 0;
  }
}

.dropdown-button {
  font: inherit;
  display: flex;
  border-radius: 4px;
  padding: 6px 8px;
  margin: 4px;
  cursor: pointer;
  align-items: center;
  color: $white;
  background: $blue;
  border: none;

  outline: none; // TODO: improve accessibility

  &:focus:not(.search-filter-active) {
    background: lighten($blue, 5);
  }

  &[disabled] {
    background-color: $gray;
  }

  > span {
    font-weight: 600;
    margin-right: 8px;
  }
}

.dropdown-outer {
  position: absolute;
  z-index: 100;
  border-radius: 4px;
  max-height: 260px;
  margin-left: 4px;
  overflow: hidden;
  border: 1px solid $blue;
  transition: all 300ms ease-in-out;

  &.dropdown-open {
    visibility: visible;
    opacity: 1;
  }

  &.dropdown-close {
    visibility: hidden;
    opacity: 0;
  }
  .scrollbar-container {
    max-height: 220px;
  }

  &.ps--active-y .dropdown-container {
    padding-right: 10px + $scrollbar-size;
  }
}

.dropdown-container {
  position: relative;
  padding: 10px;
  background-color: $white;
  border-radius: 4px;
  font-weight: 400;
  color: $blue;
  overflow: hidden;

  .dropdown-infinity-loader {
    margin: 10px 0 0;
    text-align: center;
  }
}
