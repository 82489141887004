.tracker-popup-wrapper {
  display: none;
}

.tracker-popup-container {
  font-family: myriad-pro, sans-serif;
  font-size: 0.8rem;
}

.tracker-popup-main {
  padding: 15px;
  color: $blue;
}

.tracker-popup-header {
  padding-right: 15px;
  font-size: 1.5rem;
  font-weight: 600;
}

.tracker-popup-subheader {
  margin-top: 4px;
  font-weight: 600;
}

.tracker-popup-row {
  display: flex;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.tracker-popup-col {
  flex: 3;

  &:last-child {
    flex: 4;
    font-weight: 600;
  }

  svg {
    margin-left: 5px;
  }
}

.tracker-popup-details {
  margin-top: 15px;
  padding: 15px 0;
  border-top: 1px solid $blue;
  border-bottom: 1px solid $blue;
}

.tracker-popup-flight {
  margin-top: 15px;

  .tracker-popup-row {
    .tracker-popup-col:last-child {
      flex: 10;
    }
  }
}

.tracker-popup-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  background-color: $aqua;

  .tracker-popup-options-export {
    color: $white;
    font-weight: 600;
    text-decoration: underline;

    span {
      vertical-align: middle;
    }

    svg:first-child {
      margin-right: 2px;
    }
  }

  .tracker-popup-options-right a {
    margin-left: 10px;
  }
}
