.select-container {
  font-weight: 400;
  position: relative;
  min-height: 34px;
}

.select-menu-list {
  max-height: 200px;
  position: relative;
}

.select-icons {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .select-success {
    @include successInputIndicator();
  }
}
