.change-groups-form-control {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  > .change-groups-form-label {
    flex: 1;
    padding-right: 15px;
  }

  > div:last-child {
    flex: 2;
  }
}

.change-groups-form-submit {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .change-groups-form-info {
    font-size: 0.75rem;
    font-weight: 600;
  }
}
