%page-item {
  position: relative;
  display: block;
  margin-left: -1px;
  padding: 5px 10px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0 5px;
  border-radius: 4px;

  .break {
    @extend %page-item;
  }

  .selected {
    background-color: $aqua;
  }
}

.page-item {
  > a {
    @extend %page-item;
  }

  &:not(.selected):not(.disabled) > a {
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
    }
  }

  &:first-child > a {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child > a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
