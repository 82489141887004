.api-keys-list-container {
  padding: 0 0 15px;
  color: $white;
}

.api-keys-list-item-wrapper {
  display: flex;
  align-items: center;
}

.api-keys-list-item {
  position: relative;
  margin-top: 15px;
}

.api-keys-list-item:first-child {
  margin-top: 15px;
}

.api-keys-list-item-label {
  margin-bottom: 2px;
  font-size: 0.8rem;
  font-weight: 600;
}

.api-keys-list-item-input {
  flex: 1 1 auto;
}

.api-keys-list-item-delete {
  position: relative;
  margin-left: 8px;
  @include icon-x(20px, 1px, $white);
}
