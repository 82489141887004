.trackers-list-popup-wrapper {
  display: none;
}

.trackers-list-popup-container {
  font-family: myriad-pro, sans-serif;
  font-size: 1rem;
}

.trackers-list-popup-main {
  padding: 15px;
  color: $blue;
}

.trackers-list-popup-header {
  margin-bottom: 15px;
  padding-right: 15px;
  font-size: 1.25rem;
  font-weight: 600;
}

.trackers-list-popup-content {
  max-height: 250px;
  overflow-y: auto;
}

.tracker-list-popup-item {
  padding: 4px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-of-type(odd) {
    background: rgba($black, 0.1);
  }

  &:hover {
    background: rgba($aqua, 0.2);
  }
}
