.api-keys-view-container {
  min-height: 100%;
  background-color: $blue;
}

.api-keys-view-box {
  max-width: 500px;
  padding: 30px 15px;
  margin: 0 auto;
}

.api-keys-view-header {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  color: $white;
}
