.create-partner-form-outer {
  .create-partner-header {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .vrs-input-box {
    margin-top: 15px;
  }

  button {
    width: 100%;
    margin-top: 15px;
  }
}
