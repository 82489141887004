.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gr-grow {
  flex-grow: 1;
}

$medias: (
  'xs': 'max-width: 576px',
  'sm': 'min-width: 576px',
  'md': 'min-width: 768px',
  'lg': 'min-width: 1200px',
);

@each $media, $value in $medias {
  .hide\@#{$media} {
    @media ($value) {
      display: none;
    }
  }
}

@mixin for-xs {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin for-sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin for-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-lg {
  @media (min-width: 1200px) {
    @content;
  }
}

$gridSizeValues: 1 2 3 4 5 6 7 8 9 10 11 12;

@mixin flexSize($size) {
  flex-basis: calc(100% * (#{$size} / 12));
  max-width: calc(100% * (#{$size} / 12));
}

@for $size from 1 through length($gridSizeValues) {
  .gr-#{nth($gridSizeValues, $size)} {
    @include flexSize(nth($gridSizeValues, $size));
  }
  @each $media, $value in $medias {
    * .gr-#{nth($gridSizeValues, $size)}\@#{$media} {
      @media ($value) {
        @include flexSize(nth($gridSizeValues, $size));
      }
    }
  }
}
