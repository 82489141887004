$modal-top: $header-height + $breadcrumb-height + 30px;

.modal {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  position: fixed;
  left: 0;
  top: $modal-top;
  width: 500px;
  max-height: calc(100% - #{$modal-top} - 30px);
  color: $white;
  background-color: $blue;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10000;

  @include for-xs {
    width: 100%;
  }

  &.auto-size {
    width: auto;
    min-width: 280px;
    max-width: 45%;

    @include for-xs {
      width: 100%;
      max-width: 100%;
    }
  }

  &.partner-menu-modal-container {
    background-color: $white;
    color: $blue;

    .modal-back .arrow {
      border-color: $blue;
    }

    .modal-close {
      @include icon-x(26px, 1px, $blue);
    }
  }
}

.modal-header {
  position: relative;
  padding: 15px;
  min-height: 60px;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 1px solid $gray;
}

.modal-back {
  .modal-back-text {
    font-size: 1rem;
    text-decoration: underline;
    color: $white;
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  @include icon-x(26px, 1px, $white);
}

.modal-content {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > .scrollbar-container {
    padding: 15px;

    &.ps--active-y {
      padding-right: 15px + $scrollbar-size;
    }
  }
}
