.vrs-upload-box {
  position: relative;
  width: 100%;
  height: $vrs-input-height;

  input {
    position: relative;
    width: 100%;
    height: $vrs-input-height;
    margin: 0;
    opacity: 0;
    z-index: 2;
  }

  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $vrs-input-height;
    padding-left: 10px;
    border: 1px solid $gray-blue;
    border-radius: 4px;
    background-color: $white;
    color: $blue;
    font-weight: 400;
    line-height: $vrs-input-height;
    overflow: hidden;
    z-index: 1;
  }
}
