.delete-customer-form {
  button {
    margin-top: 15px;
    float: right;
  }
}

.delete-customer-form-text {
  margin-bottom: 15px;
}

.delete-customer-form-subtext {
  margin: 8px 0;
}
