.settings-block {
  position: relative;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  > form {
    padding: 10px;
    border-radius: 4px;
    background: rgba($white, 0.1);
  }
}

.settings-header {
  margin-bottom: 5px;
  font-weight: 600;
}

.settings-warning {
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $yellow;
}

.settings-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }

  .select-container {
    flex: 1;
  }
}

.settings-item-label {
  flex: 1;
  padding-right: 15px;
}

.settings-item-controls {
  display: flex;
  position: relative;
  flex: 1;

  > div:not(.settings-hidden-input) {
    flex: 1;
  }
}

.settings-item-controls-wrapper {
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 10px;
  }

  > p {
    padding-right: 5px;
  }
}

.settings-save-btn {
  display: flex;
  justify-content: flex-end;
}
