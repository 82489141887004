.manage-view {
  h2.manage-view-header {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.manage-header {
  padding-bottom: 15px;

  &.is-button-on-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .manage-partner-name {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .manage-partner-description {
    font-weight: 400;
  }
}

.manage-content-control {
  .vrs-button {
    margin-left: 8px;
  }
}

.manage-list-content {
  position: relative;
  margin-top: 15px;
}

.manage-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;

  > :first-child {
    flex: 1;
    margin-right: 15px;
  }

  input {
    height: 30px;
    color: $white;
    background: transparent;
    border: 0;
  }

  .list-item-text {
    font-weight: 600;
    text-decoration: underline;
  }

  .list-item-edit {
    margin-right: 10px;
    visibility: hidden;
    cursor: pointer;
  }

  .list-item-remove {
    position: relative;
    visibility: hidden;
    outline: none;
    @include icon-x(18px, 1px, $white);
  }

  &:nth-of-type(odd) {
    background: rgba($white, 0.2);
  }

  &:hover {
    background: rgba($aqua, 0.5);

    .list-item-edit,
    .list-item-remove {
      visibility: visible;
    }
  }
}
