.loader {
  z-index: 10000;
  margin: auto;
  padding: 15px;
}

.vrs-loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vrs-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: hsla(0, 0%, 0%, 0.2);
  width: 100%;
  height: 100%;
}

.vrs-loader-absolute {
  position: absolute;
}

.vrs-loader-fixed {
  position: fixed;
}
