.modal-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: hsla(0, 0%, 0%, 0.2);
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.modal-alert-container {
  padding: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  color: $blue;
  background-color: $white;
  border-radius: 4px;
  transform: translate(-50%, -50%);

  @include for-xs {
    width: 100%;
  }
}

.modal-alert-header {
  position: relative;
  padding-bottom: 15px;
  min-height: 15px;
}

.modal-alert-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.modal-alert-close {
  position: absolute;
  right: 0;
  @include icon-x(16px, 1px, $blue);
}
