.trackers-map {
  height: calc(100vh - #{$header-height} - #{$breadcrumb-height});
}

.popup-wrapper {
  height: 0;
  position: absolute;
  width: 250px;
  cursor: auto;
}

.popup-container {
  position: absolute;
  width: 100%;
  left: 8px;
}

.popup-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent $white transparent transparent;
}

.popup-content {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 280px;
  transform: translate(0, -50%);
  background-color: $white;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0 0 16px 8px rgba($black, 0.1);
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  @include icon-x(15px, 2px, $blue);
}
