.vrs-input-checkbox {
  position: relative;

  input {
    display: none;
  }

  label {
    text-overflow: ellipsis;
    color: $blue;
    white-space: nowrap;
    padding-left: 24px;
    padding-bottom: 2px;
    padding-top: 2px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    -webkit-tap-highlight-color: rgba($white, 0);
    z-index: 20;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:after {
          visibility: visible;
          opacity: 0.6;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background: $light-blue;
      visibility: hidden;
      opacity: 0;
      transition: all 300ms ease-in-out;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: $white;
      border: 2px solid $light-blue;
    }
  }
  &:last-child label {
    padding-bottom: 0;
  }

  input:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
}
