.partners-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 1.25rem;

  span {
    cursor: pointer;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    .partners-list-item-remove {
      display: inline-block;
    }
  }
}

.partners-list-item-remove {
  position: relative;
  display: none;
  outline: none;
  @include icon-x(20px, 1px, $white);
}

.delete-partner-form {
  button {
    margin-top: 15px;
    float: right;
  }
}

.delete-partner-form-text {
  margin-bottom: 15px;
}
