.vrs-button {
  display: inline-block;
  height: $vrs-input-height;
  padding: 0 16px;
  text-align: center;
  background: transparent;
  font-size: 1rem;
  font-weight: 600;
  font-family: myriad-pro, sans-serif;
  white-space: nowrap;
  color: $white;
  border: 2px solid $white;
  border-radius: 30px;
  cursor: pointer;
  outline: none;

  svg {
    margin-left: 8px;
  }

  span {
    white-space: nowrap;
  }
}

.vrs-button-active,
.vrs-button:hover {
  background-color: hsla(0, 0%, 100%, 0.6);
  color: $blue;
}

.vrs-button-inverse {
  color: $blue;
  border-color: $blue;
}

.vrs-button-disabled,
.vrs-button-disabled:hover {
  background: transparent;
  color: rgba($white, 0.6);
  border-color: rgba($white, 0.6);
  cursor: default;
  pointer-events: none;
}

.vrs-button-full {
  width: 100%;
}
