.autosuggest {
  position: relative;
}

.autosuggest-alert {
  position: absolute;
}

/* ReactAutosuggest overwrites */

.react-autosuggest__container {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -50%);
    background-image: url('/assets/icons/search.svg');
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
  }
}

.react-autosuggest__suggestions-container {
  position: relative;
  display: none;
  overflow: hidden;
  z-index: 200;

  .scrollbar-container {
    max-height: 240px;
  }

  &.ps--active-y {
    padding-right: $scrollbar-size;
  }

  &.ps--active-x {
    padding-bottom: $scrollbar-size;
  }
}

.react-autosuggest__container--open {
  .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: $vrs-input-height;
    width: 100%;
    background-color: $white;
    border: 1px solid $gray-blue;
    border-top: 0;
  }

  .react-autosuggest__input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.react-autosuggest__input {
  width: 100%;
  height: $vrs-input-height;
  padding: 0 15px 0 30px;
  border: 1px solid $gray-blue;
  border-radius: 4px;
  font-family: myriad-pro, sans-serif;
  font-size: 1rem;
  color: $blue;
  outline: none;
}

.react-autosuggest__suggestion {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid $gray;
  color: $blue;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  .suggestion-container {
    display: flex;
  }

  .suggestion-values-block {
    flex: 1;
    font-weight: 600;
    padding-left: 15px;
    overflow: hidden;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .suggestion-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .suggestion-warning {
    font-size: 0.75rem;
    color: $red;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: $light-aqua;
}
