@mixin icon-x($size, $thickness, $color) {
  width: $size;
  height: $size;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: $thickness;
    background: $color;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

@mixin successInputIndicator {
  height: 18px;
  width: 18px;
  color: $green;
  animation: successIndicatorAnimation 1.5s forwards;
}
