.flight-choose-form-step-bottom {
  display: flex;
  align-items: flex-start;
}

.flight-choose-form-error {
  word-break: break-all;
  padding-right: 10px;
}

.flight-choose-form-step-title {
  font-weight: 700;
  margin-bottom: 10px;
}
