.account {
  color: inherit;
}

.account-item {
  margin-bottom: 10px;
  font-size: 1.25rem;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}
