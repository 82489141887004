.flight-search-form-wrapper {
  display: flex;
  align-items: flex-start;
}

.flight-search-form-item {
  flex: 1;
  margin-bottom: 10px;

  .vrs-input-box {
    margin-top: 6px;
  }

  &:first-child {
    margin-right: 10px;
  }
}

.flight-search-form-error {
  word-break: break-all;
  padding-right: 10px;
}
