.manage-keys-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}

.manage-keys-item-wrapper {
  display: flex;
  align-items: center;
}

.manage-keys-item-label {
  margin-bottom: 2px;
  font-size: 0.8rem;
  font-weight: 600;
}

.manage-keys-item-input {
  flex: 1 1 auto;
}

.manage-keys-item-delete {
  position: relative;
  margin-left: 8px;
  @include icon-x(20px, 1px, $white);
}

.create-keys-info {
  margin: 0 0 15px;
  font-weight: 400;
}
