.tracker-ble-devices-item {
  display: flex;
  margin-bottom: 8px;
  padding: 4px;

  &:nth-child(even) {
    background: rgba(white, 0.1);
  }
}

.tracker-ble-devices-item-mac-block {
  width: 60%;
}

.tracker-ble-devices-item-details-block {
  > p {
    &:first-child {
      margin-right: 8px;
    }

    > span:first-child {
      text-transform: capitalize;
      font-weight: 400;
    }
  }
}
