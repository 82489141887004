@import 'variables';
@import 'mixins';
@import 'grid';
@import 'animations';

@import '~minireset.css/minireset';

@import '~common/components/Accordion/accordion';
@import '~common/components/Arrow/arrow';
@import '~common/components/Autosuggest/autosuggest';
@import '~common/components/Badge/badge';
@import '~common/components/Button/button';
@import '~common/components/Breadcrumb/breadcrumb';
@import '~common/components/Checkbox/checkbox';
@import '~common/components/CopyInput/copyInput';
@import '~common/components/DateTimeInput/dateTimeInput';
@import '~common/components/Dropdown/dropdown';
@import '~common/components/Header/header';
@import '~common/components/HighlightedText/highlightedText';
@import '~common/components/Input/input';
@import '~common/components/Loader/loader';
@import '~common/components/Modal/modal';
@import '~common/components/ModalAlert/modalAlert';
@import '~common/components/Pagination/pagination';
@import '~common/components/RadioGroup/radiogroup';
@import '~common/components/Select/select';
@import '~common/components/SettingsForm/settingsForm';
@import '~common/components/Switch/switch';
@import '~common/components/TwoFactorCode/twoFactorCode';
@import '~common/components/Upload/upload';

@import '~modules/auth/components/authForm';

@import '~modules/account/views/ChangeEmailView/changeEmailView';
@import '~modules/account/views/ChangePasswordView/changePasswordView';
@import '~modules/account/views/ChangeTwoFactorView/changeTwoFactorView';

@import '~modules/menu/components/CreateKeysForm/createKeysForm';
@import '~modules/menu/components/CreateFirmwareForm/createFirmwareForm';
@import '~modules/menu/components/ImportDevicesForm/importDevicesForm';
@import '~modules/menu/components/MainMenu/mainMenu';
@import '~modules/menu/components/PartnerMenu/partnerMenu';
@import '~modules/menu/components/UpdateBLEFilterForm/updateBLEFilterForm';
@import '~modules/menu/containers/menuContainer';
@import '~modules/menu/views/AccountView/accountView';
@import '~modules/menu/views/ManageCustomersView/manageCustomersView';
@import '~modules/menu/views/ManageKeysView/manageKeysView';
@import '~modules/menu/views/PartnerCreateView/partnerCreateView';
@import '~modules/menu/views/PartnersListView/partnersListView';

@import '~modules/partners/components/APIKeysList/APIKeysList';
@import '~modules/partners/components/CreatedAPIKeys/createdAPIKeys';
@import '~modules/partners/views/APIKeysView/APIKeysView';

@import '~modules/trackers/components/ChangeGroupsForm/changeGroupsForm';
@import '~modules/trackers/components/ChangePartnerForm/changePartnerForm';
@import '~modules/trackers/components/ExportLogForm/exportLogForm';
@import '~modules/trackers/components/FlightInfo/flightInfo';
@import '~modules/trackers/components/FlightSearchForm/flightSearchForm';
@import '~modules/trackers/components/FlightChooseForm/flightChooseForm';
@import '~modules/trackers/components/MigrateTrackerForm/migrateTrackerForm';
@import '~modules/trackers/components/TrackersListPopup/trackersListPopup';
@import '~modules/trackers/components/SearchFilters/searchFilters';
@import '~modules/trackers/components/SearchFilters/searchFilters';
@import '~modules/trackers/components/TrackerDetails/trackerDetails';
@import '~modules/trackers/components/TrackerDetailsPopup/trackerDetailsPopup';
@import '~modules/trackers/components/TrackerBleDevices/trackerBleDevices';
@import '~modules/trackers/components/TrackersList/trackersList';
@import '~modules/trackers/components/TrackersMap/trackersMap';
@import '~modules/trackers/components/TrackersTable/trackersTable';
@import '~modules/trackers/views/TrackerFlightInfoView/trackerFlightInfoView';
@import '~modules/trackers/views/TrackersManagementView/trackersManagementView';

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 100%;
  font-weight: 300;
}

body {
  background-color: $white;
  font-family: myriad-pro, sans-serif;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: $white;
}

svg {
  fill: currentColor;
}

strong {
  font-weight: 600;
}

hr.divider {
  margin: 15px 0;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $gray;
}

.content {
  height: 100%;
  padding-top: $header-height;
}

.container {
  max-width: $container-width;
  margin: 0 auto;
}

.layout {
  height: 100%;
  overflow: auto;
  background-color: $blue;
  background-image: url('../images/bg-businessman.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Helpers */

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.display-flex {
  display: flex;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.vrs-error {
  font-size: 0.75rem;
  font-weight: 600;
  animation: slideErrorAnimation 0.2s ease;
}

.pure-button {
  padding: 0;
  border: none;
  background: none;
  font-family: inherit;
  font-size: 1rem;
  color: inherit;
  cursor: pointer;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
}

/* perfect-scrollbar overwrites */

.ps__rail-x {
  height: $scrollbar-size;
  opacity: 1;
  z-index: 1000;
}

.ps__rail-y {
  width: $scrollbar-size;
  opacity: 1;
  z-index: 1000;
  // Following overwrites fix the issue with the left side scrollbar in tracker details modal
  left: unset !important;
  right: 0;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: rgba($gray, 0.3);
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 1;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  opacity: 1;
  background-color: rgba($gray, 0.3);
}

.ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  bottom: 0;
  height: $scrollbar-size;
  border-radius: $scrollbar-size;
  background-color: $gray;
}

.ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  right: 0;
  width: $scrollbar-size;
  border-radius: $scrollbar-size;
  background-color: $gray;
}

.scrollbar-container.scrollbar-white {
  > .ps__rail-x > .ps__thumb-x,
  > .ps__rail-y > .ps__thumb-y {
    background-color: $white;
  }
}
