$sticky-width: 130px;
$fixed-item-width: 240px;

.trackers-table {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  table {
    display: block;
    flex-direction: column;
    position: relative;
    margin: 15px 0;
    min-height: 400px;
    max-height: 1200px;
    padding-bottom: 30px;
  }
}

.trackers-table-row {
  background-color: $blue;

  &:nth-child(odd) {
    background-color: $light-blue;
  }
}

.trackers-table-headers-item {
  padding: 5px 5px 15px 5px;
  text-align: start;
  font-weight: 400;
  position: sticky;
  top: 0;
  background-color: $blue;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.trackers-table-headers-title {
  white-space: nowrap;
}

.trackers-table-headers-text {
  padding-right: 8px;
  font-weight: 600;
}

.trackers-table-headers-filter {
  position: absolute;
  right: 10px;
  top: 8px;
}

.trackers-table-headers-input {
  height: 24px;
}

.trackers-table-empty {
  text-align: center;
}

.trackers-table-item {
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  white-space: nowrap;
  background-color: inherit;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.trackers-table-headers-item,
.trackers-table-item {
  &:first-child {
    position: sticky;
    width: $sticky-width;
    left: 0;
    z-index: 2;
  }

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.trackers-table-headers-item {
  &:first-child {
    z-index: 3;
  }
  &:last-child {
    height: 64px;
    align-items: baseline;
  }
}

// datepicker style overrides
.DateRangePickerInput {
  display: flex;
  flex-wrap: nowrap;
  background-color: transparent;
  border: 0;
}

.DateInput {
  background: none;
  width: 110px;
}

.DateInput_input {
  height: 24px;
  border: 1px solid $gray-blue;
  border-radius: 4px;
  font-family: myriad-pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: $blue;
  outline: none;

  &[disabled] {
    pointer-events: none;
    background: $gray-blue;
  }
}

.DateRangePickerInput_arrow {
  margin: 0 5px;
  color: $white;
}

.CalendarMonth_table {
  td {
    text-align: center;
  }
}

.CalendarDay__selected_span {
  background: $light-aqua;
  color: #484848;
  &:active,
  &:hover {
    background: $aqua;
    border: 1px double $aqua;
  }
}

.CalendarDay__selected {
  background: $aqua;
  color: $white;
  &:hover {
    background: $aqua;
  }
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $light-aqua;
  color: #484848;
}

.DateRangePickerInput__showClearDates {
  padding: 0;
}

.DateRangePickerInput_clearDates {
  margin: 0;
  padding: 0;
  right: 5px;
}

.DateRangePickerInput_clearDates_svg {
  fill: $blue;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  border-radius: 0;
  background: none;
}

.DateRangePicker {
  display: block;
}

.DateInput_fang,
.DateRangePicker_picker {
  z-index: 99;
}
