@keyframes slideErrorAnimation {
  0% {
    padding: 0;
    height: 0;
    opacity: 0;
  }
  80% {
    height: 15px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes successIndicatorAnimation {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-150%);
  }
}
