.auth-form {
  max-width: 460px;
  margin: 60px auto;
  padding: 0 15px;
  font-weight: 400;
  color: white;

  h1 {
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: 600;
  }

  h1 + p {
    margin-bottom: 30px;
    font-size: 1.25rem;
  }
}

.auth-form-inputs {
  .vrs-input-box {
    padding-bottom: 15px;
  }

  .vrs-input-box:last-child {
    padding-bottom: 0;
  }
}

.auth-form-link {
  padding-top: 15px;
  text-align: left;

  a {
    color: $white;
    text-decoration: underline;
  }
}

.auth-form-control {
  margin-top: 15px;

  .vrs-button {
    width: 100%;
  }
}

.auth-activate-step {
  position: relative;
  min-height: 200px;
}
