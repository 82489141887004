.breadcrumb {
  display: flex;
  padding: 0 15px;
  height: $breadcrumb-height;
  line-height: $breadcrumb-height;
  color: $white;
  background-color: $blue;
}

.breadcrumb-path {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: -17px; // Hides scrollbar in Firefox

  .breadcrumb-link {
    text-decoration: underline;
    cursor: pointer;

    &:last-child {
      text-decoration: none;
      cursor: auto;
    }
  }

  .breadcrumb-slash {
    margin: 0 8px;
  }

  &::-webkit-scrollbar {
    display: none; // Hides scrollbar in WebKit browsers
  }
}

.breadcrumb-trackers-view {
  flex-shrink: 0;
  padding-left: 15px;

  svg {
    margin-right: 4px;
  }

  .breadcrumb-trackers-view-mode {
    display: inline-block;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;

    &.active {
      color: $aqua;
    }
  }

  .breadcrumb-trackers-list {
    position: absolute;
    right: 15px;
    top: $header-height + $breadcrumb-height;
    padding: 8px 16px;
    margin-top: 15px;
    font-weight: 600;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    border-radius: 2px;
    background: $white;
    color: $black;
    z-index: 1;
  }

  .breadcrumb-separator {
    margin: 0 10px;
    border-left: 1px solid $white;
  }
}
