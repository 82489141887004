.badge {
  display: inline-block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  border: 1px solid;

  &.badge-success {
    background-color: $green;
    border: 0;
  }

  &.badge-warning {
    background-color: $yellow;
    border: 0;
  }

  &.badge-error {
    background-color: $red;
    border: 0;
  }
}
