.export-log-form {
  button {
    width: 100%;
  }

  .row {
    @include for-sm {
      padding-bottom: 15px;
    }
  }

  .log-period:nth-child(1) {
    @include for-sm {
      padding-right: 15px;
    }
  }

  .log-period {
    @include for-xs {
      padding-bottom: 15px;
    }
  }

  .export-log-form-control {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    .vrs-button:nth-child(1) {
      max-width: 220px;
    }
    .vrs-button:nth-child(2) {
      max-width: 235px;
    }
    .icon-export-log {
      margin-right: 8px;
    }
  }
}

.export-log-form-error {
  padding-top: 8px;
  text-align: end;
}
