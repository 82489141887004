.flight-info-item {
  display: flex;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.flight-info-label {
  flex: 1 0;
}

.flight-info-value {
  flex: 1;
}

.flight-info-value {
  font-weight: 600;
}

.flight-info-bottom {
  display: flex;
}
