.vrs-radio-group {
  .vrs-radio-group-item {
    position: relative;
    margin-bottom: 15px;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    text-overflow: ellipsis;
    color: $white;
    white-space: nowrap;
    margin-left: 16px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba($white, 0);
    z-index: 20;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      margin-top: 2px;
      border-radius: 16px;
      background: $white;
    }
  }

  input:checked + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: $light-blue;
  }
}
