.tracker-list-item {
  padding: 10px;

  &:nth-child(odd) {
    background: hsla(0, 100%, 100%, 0.1);
  }
}

.tracker-name {
  font-weight: 600;
  text-decoration: underline;
  word-break: break-all;
}

.tracker-partner {
  margin: 4px 0;
}

.tracker-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .tracker-status {
    span:nth-child(2) {
      margin-right: 2px;
      margin-left: 10px;
    }

    svg:last-child {
      margin-left: 10px;
    }
  }

  .tracker-last-update {
    margin-top: 4px;
  }
}
