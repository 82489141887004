.arrow {
  display: inline-block;
  border-left: 1px solid $white;
  border-bottom: 1px solid $white;
  vertical-align: middle;
}

.arrow-up {
  transform: rotate(135deg) translate(25%, -25%);
}

.arrow-down {
  transform: rotate(-45deg) translate(0%, -25%);
}

.arrow-right {
  transform: rotate(-135deg);
}

.arrow-left {
  transform: rotate(45deg);
}
