.vrs-switch {
  position: relative;
  align-items: center;

  input {
    display: none;
  }

  label {
    align-items: center;
    display: flex;
    text-overflow: ellipsis;
    color: $blue;
    white-space: nowrap;
    margin-left: 8px;
    padding-left: 32px;
    line-height: 18px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba($white, 0);
    z-index: 20;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 32px;
      height: 16px;
      border-radius: 8px;
      background: $white;
      border: 1px solid $light-blue;
    }

    &:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: $light-blue;
      transition: all 0.2s;
    }
  }

  input:checked + label:before {
    background: $light-blue;
  }

  input:checked + label:after {
    background: $white;
    transform: translateX(16px);
  }
}
