.tracker-details {
  padding: 10px;
  border-radius: 4px;
  background: rgba($white, 0.1);
}

.tracker-details-header {
  margin-bottom: 5px;
  font-weight: 600;
}

.tracker-details-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .tracker-details-name {
    margin: 0;
  }

  .tracker-details-value {
    margin: 0;
    font-weight: 600;
  }
}
