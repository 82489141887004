.copy-input {
  position: relative;

  .vrs-input {
    padding-right: 40px;
  }

  .copy-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    filter: opacity(0.5);
  }

  .copy-icon:hover {
    filter: opacity(1);
  }

  .copy-input-tooltip {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 80px;
    padding: 4px;
    background: rgba($aqua, 0.8);
    border-radius: 4px;
    text-align: center;
    transition: opacity 300ms ease-in-out;
    display: none;
  }

  .copy-input-tooltip.input-tooltip-show {
    z-index: 1000;
    display: block;
  }
}
