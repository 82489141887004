$container-width: 1200px;
$breadcrumb-height: 36px;
$header-height: 70px;
$scrollbar-size: 7px;

$white: #fff;
$black: #000;
$blue: #123363;
$light-blue: #2a4873;
$gray: #aaa;
$gray-blue: #a0adc1;
$aqua: #3d9cbb;
$light-aqua: rgba($aqua, 0.2);
$red: #ff0000;
$yellow: #ffff00;
$green: #1e9157;

$vrs-input-height: 34px;
