.create-firmware-form {
  display: flex;
  align-items: center;

  .vrs-button {
    margin-left: 8px;
  }
}

.create-firmware-form-controls {
  flex: 1;
}

.create-firmware-form-controls-row {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.create-firmware-form-checkbox {
  & label {
    color: white;
  }
}

.create-firmware-form-selector {
  flex: 1;
  margin-left: 8px;
}
