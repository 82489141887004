.vrs-input-box {
  position: relative;

  .vrs-input-icons {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .vrs-input-success {
    @include successInputIndicator();
  }
}

.vrs-input {
  width: 100%;
  height: $vrs-input-height;
  padding: 0 10px;
  border: 1px solid $gray-blue;
  border-radius: 4px;
  font-family: myriad-pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: $blue;
  outline: none;

  &[disabled] {
    pointer-events: none;
    background: $gray-blue;
  }

  &.vrs-file-input {
    cursor: pointer;
    caret-color: transparent;
  }
}
