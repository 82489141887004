.accordion {
  margin-bottom: 15px;
  color: $white;

  &.active {
    .accordion-header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .accordion-content {
      visibility: visible;
    }
  }

  .vrs-input-checkbox {
    margin: 5px 0;

    label {
      display: block;
    }
  }

  .accordion-infinity-loader {
    margin: 15px 0;
  }
}

.accordion-header {
  width: 100%;
  padding: 8px 15px;
  text-align: left;
  font-weight: 600;
  color: $white;
  background-color: $blue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &[disabled] {
    background-color: $gray;
  }
}

.accordion-content {
  position: relative;
  overflow: hidden;
  transition: all 300ms ease-out;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid $blue;
  border-top: none;
  visibility: hidden;
  color: $blue;

  .scrollbar-container {
    padding-left: 16px;
    max-height: 204px;
  }
}
