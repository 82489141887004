.vrs-date-time-picker {
  color: $blue;
}

/* react-datetime overwrites */

.rdtPicker {
  position: fixed;
  border-radius: 4px;
  z-index: 100;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $aqua;
}

.rdtPicker td.rdtToday:before {
  border-bottom-color: $aqua;
}
