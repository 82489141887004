.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: $header-height;
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba($black, 0.15);
}

.header-content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.header-logo {
  margin: 0 auto;
  padding: 0 15px;
}

.header-autosuggest-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.header-autosuggest-wrapper .srcollbar-container {
  height: 240px;
  min-width: 280px;
}

.header-autosuggest {
  flex: 1;
}

.header-autosuggest-switch {
  margin-left: 6px;
}

.header-autosuggest-desktop {
  display: none;

  @include for-md {
    display: flex;
  }
}

// TODO: Fix this workaround for CSS specificity
.header-filters-desktop.header-filters-desktop {
  flex-basis: 55%;
  display: none;

  @include for-md {
    display: flex;
  }
}

.header-collapse {
  position: fixed;
  top: $header-height;
  width: 100%;
  padding: 0 15px;
  background-color: $white;

  @include for-md {
    display: none;
  }

  > .scrollbar-container {
    max-height: calc(100vh - #{$header-height} - #{$vrs-input-height} - 15px);
    padding-right: 15px;
  }

  .header-collapse-content {
    position: relative;
    margin-top: 15px;
    overflow: hidden;

    &.ps--active-y {
      padding-right: 15px;
    }
  }
}
