.tracker-flight-info-view-section {
  margin-bottom: 15px;
}

.tracker-flight-info-view-subtitle {
  margin-bottom: 5px;
  font-weight: 600;
}

.tracker-flight-info-view-section-content {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  background: rgba($white, 0.1);
}

.tracker-flight-info-view-section-button {
  display: block;
  margin-left: auto;
}
