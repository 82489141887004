.search-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .dropdown-container {
    min-width: 140px;
    max-width: 400px;
    padding-right: 16px;

    .dropdown-option {
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
    }
  }

  .vrs-input-checkbox label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-button {
    white-space: nowrap;
  }

  .dropdown-button-outer:last-child > .dropdown-button {
    margin-right: 0;
  }
}

.search-filters.collapse {
  display: block;

  .search-filters-clear {
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
}

.search-filter-active {
  background-color: $aqua;
}

.accordion.search-filter-active .accordion-header {
  background-color: $aqua;
}

.search-filters-clear {
  display: flex;
  align-items: center;
  order: 1;
  margin-left: 8px;
  .vrs-button {
    align-items: center;
    border: none;
    svg {
      margin: 0;
    }
  }
}
